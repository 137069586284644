import { Spin as Hamburger } from 'hamburger-react';
import { CornerBanner } from '..';

type Props = {
  logo: string;
  setMenuHovering: (menuHovering: boolean) => void;
  menuHovering: boolean;
  navStatus: boolean;
  toggleNavStatus: () => void;
};

const Nav = ({
  logo,
  setMenuHovering,
  menuHovering,
  navStatus,
  toggleNavStatus,
}: Props) => {
  return (
    <nav className="flex  w-full justify-center relative h-[110px] bg-[#40707c] px-5">
      <CornerBanner banner={false} />
      <div className="flex w-full justify-between items-center max-w-5xl">
        {/* Logo */}
        <div className="flex h-[90px]">
          <a href="/" className="items-center">
            <img
              src={logo}
              className="h-[90px] w-auto"
              alt="kenworthy chiropractic"
            />
          </a>
        </div>
        {/* Menu Button */}
        <div className="flex items-center">
          <p className="hidden md:inline text-xl text-white font-bold cursor-default">
            Menu &nbsp;
          </p>

          <button
            onMouseEnter={() => {
              setMenuHovering(true);
            }}
            onMouseLeave={() => {
              setMenuHovering(false);
            }}
            onClick={() => toggleNavStatus()}
            type="button"
            className="flex items-center h-fit text-xl text-white font-bold"
          >
            {menuHovering && !navStatus ? (
              <Hamburger
                toggled={navStatus}
                toggle={toggleNavStatus}
                size={40}
                direction="right"
                label="Menu"
              />
            ) : (
              <Hamburger
                toggled={navStatus}
                toggle={toggleNavStatus}
                direction="right"
                label="Menu"
              />
            )}
          </button>
        </div>
      </div>
    </nav>
  );
};

export default Nav;
