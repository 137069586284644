import { HomeNav, Nav, UpperHeaderBar } from '../index';
import React, { useEffect, useRef, useState } from 'react';

import Logo from '../../assets/images/logo.png';
import { useLocation } from 'react-router-dom'; // Import if using React Router

type Props = {
  navStatus: boolean;
  toggleNavStatus: () => void;
};

const Header = ({ navStatus, toggleNavStatus }: Props) => {
  // Holds whether the menu is being hovered
  const [menuHovering, setMenuHovering] = useState(false);

  // Gets the current path using React Router
  const location = useLocation().pathname;

  // Holds the current scroll Y number
  const [scrollY, setScrollY] = useState(0);

  // Reference to the div holding the scrollable header
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // Function to handle scroll events
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };

    // Add event listener for scroll
    window.addEventListener('scroll', handleScroll);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []); // Empty dependency array ensures this runs only on mount/unmount

  // Effect to update header class based on scroll position
  useEffect(() => {
    if (ref.current) {
      if (scrollY > 300) {
        ref.current.className = 'fixed top-0 w-full z-[10] ease-in-out duration-300 transition-all';
      } else {
        ref.current.className = 'fixed top-[-300px] w-full z-[10] ease-in-out duration-300 transition-all';
      }
    }
  }, [scrollY]);

  return (
    <>
      {/* Scrolled Header */}
      <div ref={ref}>
        <div className="w-full">
          <UpperHeaderBar />
          <Nav
            logo={Logo}
            setMenuHovering={setMenuHovering}
            toggleNavStatus={toggleNavStatus}
            navStatus={navStatus}
            menuHovering={menuHovering}
          />
        </div>
      </div>

      {/* NavBar Container */}
      <header className="w-full z-[1]">
        <UpperHeaderBar />
        {location !== '/' ? (
          <Nav
            logo={Logo}
            setMenuHovering={setMenuHovering}
            toggleNavStatus={toggleNavStatus}
            navStatus={navStatus}
            menuHovering={menuHovering}
          />
        ) : (
          <HomeNav
            logo={Logo}
            setMenuHovering={setMenuHovering}
            toggleNavStatus={toggleNavStatus}
            navStatus={navStatus}
            menuHovering={menuHovering}
          />
        )}
      </header>
    </>
  );
};

export default Header;