import { DropDownItem, PageTitle } from '../../components';
import './styles.css';
import {
  pregnancyInfo,
  pregnancyInfancyInfo,
} from '../../static/pregnancy-care.js';

import BgImage from '../../assets/images/pregnancy.png';
import PelvisWebM from '../../assets/animations/animation-pelvis.webm';
import PelvisMp4 from '../../assets/animations/animation-pelvis.mp4';
import { PregnancyProps } from '../../types/types';

import Fade from 'react-reveal/Fade';

const PregnancyAndInfancy = () => {
  return (
    <div className="bg-white py-12">
      <div className="max-w-5xl mx-auto px-5">
        <PageTitle title="Pregnancy & Infancy" />
        <div className="relative px-5 pregnancy-info-container">
          <Fade distance="30px" bottom>
            <img
              src={BgImage}
              alt="massage"
              className="absolute top-[-280px] right-[-70px] h-[550px] w-auto z-[0] pregnancy-image"
            />
          </Fade>
          <Fade distance="30px" bottom>
            <div className="relative mr-[33%] z-[2] text-container">
              <p className="text-gray-600">
                Chiropractic care is a safe, gentle and drug free form of
                treatment for pregnancy related conditions.
              </p>
              <br />
              <p className="text-gray-600">
                According to the American Pregnancy Association “there are no
                known contraindications to chiropractic care throughout
                pregnancy”.
                <a
                  href="/citations#B1"
                  className="text-[#846bb9] hover:text-[#6bb2b9] ml-[2px]"
                >
                  <sup>[B1]</sup>
                </a>
              </p>
            </div>
          </Fade>
          <Fade left distance="400px" duration={5000}>
            <video className="animated--hips" loop autoPlay muted playsInline>
              <source src={PelvisWebM} type="video/webm" />
              <source src={PelvisMp4} type="video/mp4" />
            </video>
          </Fade>
        </div>
        <div>
          {/* Pregnancy info loop */}
          {pregnancyInfo.map(
            ({
              title,
              points,
              citationNumber,
              additionalText,
            }: PregnancyProps) => (
              <Fade bottom distance="30px">
                <DropDownItem
                  listStyled
                  data={points}
                  title={title}
                  additionalText={additionalText}
                  citationNumber={citationNumber}
                  citationLink="/citations"
                  disabled={false}
                  grid={false}
                />
              </Fade>
            )
          )}
        </div>

        <div className="mt-8">
          <PageTitle title="Infancy" />
          <Fade distance="30px" bottom>
            <p className="text-gray-600 mb-4 px-5">
              A study of 1,250 babies […] found that 95% of the group were not
              only misaligned but also had cervical strain. [The researcher]
              also noted that the infants responded with immediate muscular
              relaxation and had a greater ability to sleep after specific
              spinal adjustments
              <a
                href="/citations#B4"
                className="text-[#846bb9] hover:text-[#6bb2b9] ml-[2px]"
              >
                <sup>[B4]</sup>
              </a>
            </p>
          </Fade>
          {/* Infancy Loops */}
          {pregnancyInfancyInfo.map(
            ({
              title,
              points,
              citationNumber,
              additionalText,
            }: PregnancyProps) => (
              <Fade bottom distance="30px">
                <DropDownItem
                  listStyled
                  data={points}
                  title={title}
                  additionalText={additionalText}
                  citationNumber={citationNumber}
                  citationLink="/citations"
                  disabled={false}
                  grid={false}
                />
              </Fade>
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default PregnancyAndInfancy;
