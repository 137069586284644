import { HoursTable, FeesTable, PageTitle } from '../../components';
import HipAnimationWebM from '../../assets/animations/animation-hip.webm';
import HipAnimationMp4 from '../../assets/animations/animation-hip.mp4';

import { fees, openingHours } from '../../static/fees-opening';

import './styles.css';

const Fade = require('react-reveal/Fade');

const FeesAndOpening = () => {
  return (
    <div className="bg-white py-12">
      <div className="max-w-5xl mx-auto px-5">
        <PageTitle title="Fees & Opening Hours" />
        <div className="w-full h-[50px] relative animated--hip-container">
          <Fade right distance="400px" duration={5000}>
            <video
              className="h-[200px] w-[200px] top-[-180px] right-[-50px] absolute "
              loop
              autoPlay
              muted
              playsInline
            >
              <source src={HipAnimationWebM} type="video/webm" />
              <source src={HipAnimationMp4} type="video/mp4" />
            </video>
          </Fade>
        </div>
        <Fade bottom distance="30px">
          <div className="w-full mt-8 flex flex-col md:flex-row justify-between items-center md:items-stretch mx-auto max-w-5xl">
            <FeesTable fees={fees} />
            <HoursTable openingHours={openingHours} />
          </div>
        </Fade>
      </div>
    </div>
  );
};

export default FeesAndOpening;
