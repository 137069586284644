import { PageTitle } from '../../components';

const Fade = require('react-reveal/Fade');

const Bookings = () => {
  return (
    <div className="bg-white py-12">
      <div className="max-w-5xl mx-auto px-5">
        <PageTitle title="Online Bookings" />
        <div className="px-5">
          <Fade bottom distance="30px">
            <p className="text-gray-600">
              <span className="font-bold">Please Note:</span> All new patients
              are required to pay for their first appointment when booking
              online. Should an appointment need to be cancelled we will offer a
              full refund on the condition that we are given a minimum of 24hrs
              notice.
            </p>
          </Fade>
        </div>
        <div className="px-5">
          <Fade bottom distance="30px">
            <iframe
              title="booking"
              src="https://kenworthy-chiropractic.cliniko.com/bookings?embedded=true"
              width="100%"
              height="1000"
            ></iframe>
          </Fade>
        </div>
      </div>
    </div>
  );
};

export default Bookings;
