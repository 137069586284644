import Becky from '../assets/images/people/becky.jpeg'
import Emily from '../assets/images/people/emily.jpg'
import Johanna from '../assets/images/people/johanna.jpg'
import Nicola from '../assets/images/people/nicola.jpeg'
import Stuart from '../assets/images/people/stuart.jpg'
import Tanseem from '../assets/images/people/tanseem.jpg'

const team = [{
    "name": "Johanna McCormick",
    "title": "Doctor of Chiropractic",
    "description": [{
        "paragraph": "Johanna McCormick was born in Coleraine, Northern Ireland not far from the Giant's Causeway. Johanna was always very sporty at school and won a number of awards for her achievements whilst simultaneously developing a love of disco."
    },
    {
        "paragraph": "Johanna moved to the UK to study in the 1990 s and gained a Bsc(Hons) in Psychology at Uclan. Johanna took her PGCE shortly afterwards and took up a position teaching A - level psychology at St.Mary's college Blackburn. Johanna greatly enjoyed her time as a teacher and had a special fondness for her naughtier pupils. However, after nine years she felt that she needed to move on and find a career that would allow her to help even more people."
    },
    {
        "paragraph": "At the time Johanna's husband was receiving treatment from a McTimoney chiropractor for a bad back and Johanna was impressed with the quality of the treatment and the positive impact it had upon her husband's life. Soon afterwards Johanna began training as a chiropractor at the McTimoney College in Abingdon, Oxfordshire"
    },
    {
        "paragraph": "Whilst studying full time Johanna also worked for the NHS in various roles and the hard work and commitment of the people she worked with had a profound impact upon her and her attitude towards her patients."
    },
    {
        "paragraph": "After 5 years of intensive full time study Johanna qualified as a Doctor of Chiropractic and now, after a stint working in Manchester city centre, Johanna is available to take appointments at Kenworthy Chiropractic in Preston city centre. Johanna still loves disco."
    }
    ],
    "image": Johanna
},
{
    "name": "Becky Gillespie",
    "title": "Doctor of Chiropractic",
    "description": [{
        "paragraph": "Becky’s medical journey began with the NHS when she spent several years working as a GP’s Receptionist and more recently has worked as a vaccinator and phlebotomist."
    },
    {
        "paragraph": "While Becky’s primary focus is McTimoney Chiropractic, she is also qualified to practice Diversified and Soft Tissue techniques."
    },
    {
        "paragraph": "In her spare time, Becky enjoys foreign travel, South Africa being a recent highlight. Although Becky enjoys taking time to relax, she is always seeking out new experiences and adventures such as skiing, shark cage diving and sky diving."
    },
    {
        "paragraph": "Closer to home, Becky supports her family's motor racing endeavours and has even raced a single-seater car at Oulton Park,  a circuit once graced by the late great Ayrton Senna."
    },
    {
        "paragraph": "Animals are another of Becky’s great passions, and somewhere further down the line, she hopes to gain a degree in animal chiropractic/physiotherapy - And yes, that’s a thing. How else do you think those big, shiny racehorses stay in shape?"
    }
    ],
    "image": Becky
},
{
    "name": "Stuart Kenworthy",
    "title": "Clinic Manager",
    "description": [{
        "paragraph": "Stuart Kenworthy is Johanna's husband. He has two daughters and one stepson;Arianne aged 26, Tishan aged 24 and Kane aged 21. Stuart is a Prestonian born and bred and he began his working life as a photographer at Lancashire Police HQ."
    },
    {
        "paragraph": "Stuart left Preston briefly to work on a book for the University of Leicester and when his contract ended he stayed on to study sociology earning a double first. Having enjoyed the informal atmosphere of university life Stuart realised he was no longer suited to sensible employment, so he tried his hand at writing TV comedy instead. Stuart went on to work on a number of award winning shows such as Smack The Pony;Green Wing and TV Burp before getting his own BBC3 show, Scallywagga."
    },
    {
        "paragraph": "More recently Stuart has worked in children's TV and animation, but after 17 years he's decided to call it a day so he can help Johanna run her chiropractic clinic. In his spare time Stuart cooks Indian food, watches Twin Peaks and takes photographs that only he appreciates."
    }
    ],
    "image": Stuart
},

{
    "name": "Emily Kitching",
    "title": "Chiropractic Assistant",
    "description": [{
        "paragraph": "Emily was born in sunny Preston, but she has lived all her life in wildest Longridge (only joking Longridge). At a mere six weeks olds Emily was diagnosed with progressive infantile scoliosis making her the youngest child to receive such a diagnosis in the UK. To remedy the situation Emily has to wear a cast on her back for four long years– Which must have been tough for a little girl."
    },
    {
        "paragraph": "Emily claims she wasn't a very good student, but she came good when it mattered and left school with all her GCSE's. Emily then went on to study for the public service level 3 extended and passed with the highest marks possible(triple distinction star). With her education going from strength to strength Emily was accepted to study law and criminology at Lancaster university, but she put her studies on hold whilst she started a family."
    },
    {
        "paragraph": "Emily now lives with Thomas, her partner of six years and their two year old son, Jack. Like Emily, Jack had a difficult start as he was born with shoulder dystocia and he needed physiotherapy from being one week old(Emily says she wishes she had known Johanna back then)."
    },
    {
        "paragraph": "Emily worked in retail for over 3 years and first came to Kenworthy chiropractic as a patient, before being snapped up to become our very first chiropractic assistant. Welcome to the team Emily."
    }
    ],
    "image": Emily
},

{
    "name": "Nicola Banks",
    "title": "Chiropractic Assistant",
    "description": [{
        "paragraph": "Nicola is a Preston girl born and bred. She has five brothers and sisters and spent her school days at St. Cuthbert Mayne High School before moving on to Preston College to study nursing."
    },
    {
        "paragraph": "After graduating from college Nicola had her son, John, and started work for the family business, Thomas Banks and Co Ltd, where she remained for the next 34 years. This well known local coal merchants had fuelled factories and homes of Preston warm through many a long winter, but with the demand for coal tailing off Nicola decided it was time to move on."
    },
    {
        "paragraph": "For the next five years Nicola worked at The Range on The Queens Retail Park. Within six months of her arrival she’d been fast tracked to the position of supervisor, then sales manager before being moved up to the role of assistant manager."
    },
    {
        "paragraph": "Nicola thrived during her time at The Range, but a back injury made her realise she had to find herself a job that was less physically demanding. It was at this point that Nicola made the exceptionally wise decision to address her back issue with a course of treatment at Kenworthy Chiropractic. As luck would have it the clinic was advertising for a new front of house team member and it soon became apparent that Nicola was the ideal woman for the job."
    },
    {
        "paragraph": "Today Nicola still lives in Preston with her partner of 32 year and their daughter Ella and is a grandma to Tyler."
    }
    ],
    "image": Nicola
},

]



export default team;


export const pastTeam = [
    {
        "name": "Tanseem",
        "title": "Massage Therapist",
        "description": [{
            "paragraph": "Tasneem lives at home with her parents and two siblings in the leafy suburb of Fulwood. Tasneem completed her A - Levels in History, Graphic Design and IT to a very high standard and is considering a career as a primary school teacher, and she is currently teaching Modern Arabic to children on the weekends. Nonetheless, Johanna is nudging her towards a career in chiropractic, so watch this space."
        },
        {
            "paragraph": "Tasneem has many hobbies that keep her occupied when she's not at work. These include photography and digital editing;Art of all types, reading and she's also a bit of a foodie. Tasneem settled in almost immediately as part of the Kenworthy Chiropractic team and is already a firm favourite with many of our patients"
        }
        ],
        "image": Tanseem
    },
]