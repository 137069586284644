import React from 'react';
import { DropDownItem, PageTitle } from '../../components';

import {
  Benefits,
  HighImpact,
  LowImpact,
  AthleatsWithTreatment,
} from '../../static/sport-care';

import BgImage from '../../assets/images/sports.png';

import './styles.css';

const Fade = require('react-reveal/Fade');

const SportsAndInjury = () => {
  return (
    <div className="bg-white py-12">
      <div className="max-w-5xl mx-auto px-5">
        <PageTitle title="Sport & Injury" />
        <div className="relative px-5 sport-info-container">
          <Fade distance="30px" bottom>
            <img
              src={BgImage}
              alt="running"
              className="absolute top-[-130px] right-[-70px] h-[550px] w-auto z-[0] sport-image"
            />
          </Fade>
          <Fade distance="30px" bottom>
            <div className="relative mr-[33%] z-[2] text-container">
              <p className="text-gray-600">
                Exercise physiologist Sean Atkins, PhD, estimates that in excess
                of 90% top class athletes use chiropractic care on a regular
                basis to prevent injury and improve performance.
                <a
                  href="/citations#C1"
                  className="text-[#846bb9] hover:text-[#6bb2b9] ml-[2px] mr-[2px]"
                >
                  <sup>[C1]</sup>
                </a>
              </p>
              <br />
              <p className="text-gray-600">
                Research has shown that chiropractic treatment can improve
                athletic performance by as much as 16.7% over a two week period.
                <a
                  href="/citations#C2"
                  className="text-[#846bb9] hover:text-[#6bb2b9] ml-[2px] mr-[2px]"
                >
                  <sup>[C2]</sup>
                </a>
                The report also concluded that properly aligned athletes react
                faster, coordinate better, execute fine movements with improved
                accuracy and precision.
              </p>
            </div>
          </Fade>
        </div>
        <Fade bottom distance="30px" cascade>
          <div>
            <Fade bottom distance="30px">
              <DropDownItem
                data={HighImpact}
                title="High-impact athletes"
                disabled={false}
                grid={false}
                listStyled={false}
                list={false}
              />
            </Fade>
            <Fade bottom distance="30px">
              <DropDownItem
                data={LowImpact}
                title="Low-impact athletes"
                disabled={false}
                grid={false}
                listStyled={false}
                list={false}
              />
            </Fade>
            <Fade bottom distance="30px">
              <DropDownItem
                listStyled
                data={Benefits}
                title="Potential benefits of chiropractic treatment"
                disabled={false}
                grid={false}
                list={false}
              />
            </Fade>
          </div>
          <div className="mt-8">
            <p className="text-gray-600 mb-4 px-5">
              Chiropractic treatment can also promote physical strength. For
              example, one study, national level judo athletes showed a 16%
              improvement in grip strength after undergoing only three
              chiropractic sessions
              <a
                href="/citations#C3"
                className="text-[#846bb9] hover:text-[#6bb2b9] ml-[2px]"
              >
                <sup>[C3]</sup>
              </a>
            </p>
            <Fade bottom distance="30px">
              <DropDownItem
                listStyled
                data={AthleatsWithTreatment}
                title="Athletes that have used chiropractic treatment"
                citationNumber="C3"
                citationLink="/citations"
                disabled={false}
                grid={false}
                list={false}
              />
            </Fade>
          </div>
        </Fade>
      </div>
    </div>
  );
};

export default SportsAndInjury;
