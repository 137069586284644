import AMp4 from '../assets/video/a-video.mp4'
import AWebM from '../assets/video/a-video.webm'
import APoster from '../assets/video/a-poster.png'
import ASub from '../assets/video/a-video.vtt'
import Abg from '../assets/video/a.png'

import BMp4 from '../assets/video/b-video.mp4'
import BWebM from '../assets/video/b-video.webm'
import BPoster from '../assets/video/b-poster.png'
import BSub from '../assets/video/b-video.vtt'
import Bbg from '../assets/video/b.png'

import CMp4 from '../assets/video/c-video.mp4'
import CWebM from '../assets/video/c-video.webm'
import CPoster from '../assets/video/c-poster.png'
import Cbg from '../assets/video/c.png'

import DMp4 from '../assets/video/d-video.mp4'
import DWebM from '../assets/video/d-video.webm'
import DPoster from '../assets/video/d-poster.png'
import Dbg from '../assets/video/d.png'

import EMp4 from '../assets/video/e-video.mp4'
import EWebM from '../assets/video/e-video.webm'
import EPoster from '../assets/video/e-poster.png'
import Ebg from '../assets/video/e.png'

import FMp4 from '../assets/video/f-video.mp4'
import FWebM from '../assets/video/f-video.webm'
import FPoster from '../assets/video/f-poster.png'
import Fbg from '../assets/video/f.png'
import GMp4 from '../assets/video/g-video.mp4'
import GWebM from '../assets/video/g-video.webm'
import GPoster from '../assets/video/g-poster.png'
import Gbg from '../assets/video/g.png'

import HMp4 from '../assets/video/h-video.mp4'
import HWebM from '../assets/video/h-video.webm'
import HPoster from '../assets/video/h-poster.png'
import Hbg from '../assets/video/h.png'


export const AllVideoExercises = [
  {
    id: 1,
    letter: 'a',
    btnBg: Abg,
    videoData: {
      letter: 'a',
      title: 'The Cat And The Camel',
      description: 'Loosens up the lower spine.',
      videoMp4: AMp4,
      videoWebM: AWebM,
      poster: APoster,
      sub: ASub,
    }
  },
  {
    id: 2,
    letter: 'b',
    btnBg: Bbg,
    videoData: {
      letter: 'b',
      title: 'The Child Pose',
      description: 'Stretches out the spine, hips, thighs and ankles.',
      videoMp4: BMp4,
      videoWebM: BWebM,
      poster: BPoster,
      sub: BSub,
    }
  },
  {
    id: 3,
    letter: 'c',
    btnBg: Cbg,
    videoData: {
      letter: 'c',
      title: 'The Bridge',
      description: 'Increases core stability.',
      videoMp4: CMp4,
      videoWebM: CWebM,
      poster: CPoster,
      sub: null,
    }
  },
  {
    id: 4,
    letter: 'd',
    btnBg: Dbg,
    videoData: {
      letter: 'd',
      title: 'The Supine Piriformis Stretch',
      description: 'Relieves sciatica.',
      videoMp4: DMp4,
      videoWebM: DWebM,
      poster: DPoster,
      sub: null,
    }
  },
  {
    id: 5,
    letter: 'e',
    btnBg: Ebg,
    videoData: {
      letter: 'e',
      title: 'The Outer Hip Piriformis Stretch',
      description: 'Relieves sciatica.',
      videoMp4: EMp4,
      videoWebM: EWebM,
      poster: EPoster,
      sub: null,
    }
  },
  {
    id: 6,
    letter: 'f',
    btnBg: Fbg,
    videoData: {
      letter: 'f',
      title: 'The Long Adductor',
      description: 'Stretches out the inner thigh.',
      videoMp4: FMp4,
      videoWebM: FWebM,
      poster: FPoster,
      sub: null,
    }
  },
  {
    id: 7,
    letter: 'g',
    btnBg: Gbg,
    videoData: {
      letter: 'g',
      title: 'The Short Adductor Stretch',
      description: 'Stretches the top of the inner thigh.',
      videoMp4: GMp4,
      videoWebM: GWebM,
      poster: GPoster,
      sub: null,
    }
  },
  {
    id: 8,
    letter: 'h',
    btnBg: Hbg,
    videoData: {
      letter: 'h',
      title: 'The Pec Stretch',
      description: 'Lengthens the pecs and stops the shoulders from rolling forwards.',
      videoMp4: HMp4,
      videoWebM: HWebM,
      poster: HPoster,
      sub: null,
    }
  },
]