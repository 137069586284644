import ApplePay from '../../assets/images/payments/apple-pay.png';
import Maestro from '../../assets/images/payments/maestro.png';
import Mastercard from '../../assets/images/payments/mastercard.png';
import Visa from '../../assets/images/payments/visa.png';

type TreatmentProp = {
  title: string;
  cost: string;
};

type Treatments = {
  name: string;
  options: TreatmentProp[];
};

type Props = {
  fees: Treatments[];
};

const FeesTable = ({ fees }: Props) => {
  return (
    <div className="w-full m-4 bg-gray-100 border-2 border-black-100 h-auto p-10 flex flex-col justify-center min-w-[200px] max-w-xl">
      <div className="text-[#846bb9] flex flex-col justify-center items-center">
        <h2 className="text-2xl font-semibold ">Fees</h2>
        {/* <p className="">(from 01/12/21)</p> */}
      </div>
      {/* Fees Area */}
      <div className="max-w-4xl">
        {fees.map(({ name, options }) => (
          <div className="flex flex-col justify-center w-full my-4 border-b-2 border-gray-300">
            <div className="flex flex-row justify-between py-2 text-gray-600">
              <p>{name}</p>
            </div>
            {options.map(({ title, cost }) => (
              <div className="flex flex-row justify-between py-2 text-gray-500">
                <p className="text-sm">{title}</p>
                <p className="text-sm font-bold">£{cost}</p>
              </div>
            ))}
          </div>
        ))}
      </div>
      {/* Additional Info */}
      <div className="max-w-4xl text-gray-600">
        <h3 className="text-lg text-[#846bb9] text-start">
          Additional Information
        </h3>
        <p className="mb-1">
          Payment at the clinic is by card and cash. We accept Visa, Mastercard,
          Maestro and Apple pay.
        </p>
        <div className="flex flex-row justify-start mb-1">
          <img src={Visa} alt="Visa Card" className="h-[30px] w-auto" />
          <img src={Mastercard} alt="Mastercard" className="h-[30px] w-auto" />
          <img src={Maestro} alt="Maestro Card" className="h-[30px] w-auto" />
          <img src={ApplePay} alt="Apple Pay" className="h-[30px] w-auto" />
        </div>
        <p className="mb-1">
          Please give 24hrs notice should you need to cancel an appointment, or
          you will be liable for payment of the full fee.
        </p>
      </div>
    </div>
  );
};

export default FeesTable;
