import Fade from 'react-reveal/Fade';

import { useState } from 'react';
import { FiTarget } from 'react-icons/fi';
import './styles.css';
import {
  backNeckShoulder,
  painDiscomfortStiffness,
} from '../../static/pain-relief-types';

const TabMenu = () => {
  const [selectedButton, setSelectedButton] = useState(1);

  return (
    <div className="p-10 mt-[50px] bg-white">
      <Fade distance="30px" bottom>
        <div className="border-2 border-slate-300 w-full h-auto mx-auto rounded-lg flex flex-cols">
          <ul>
            <li>
              <button
                onClick={() => setSelectedButton(1)}
                type="button"
                className={`table-links ${selectedButton === 1 && 'active'}`}
              >
                Back, neck & shoulder pain
              </button>
            </li>
            <li>
              <button
                onClick={() => setSelectedButton(2)}
                type="button"
                className={`table-links ${selectedButton === 2 && 'active'}`}
              >
                Pain, discomfort & stiffness in joints
                <div className="btn-arrow"></div>
              </button>
            </li>
            <li>
              <a
                onClick={() => setSelectedButton(3)}
                href="sport-injury"
                className={`table-links ${selectedButton === 3 && 'active'}`}
              >
                Sport injuries
              </a>
            </li>
            <li>
              <a
                onClick={() => setSelectedButton(4)}
                href="pregnancy-infancy"
                className={`table-links ${selectedButton === 4 && 'active'}`}
              >
                Pregnancy related pain (postnatal & antenatal)
              </a>
            </li>
            <li>
              <button
                onClick={() => setSelectedButton(5)}
                type="button"
                disabled
                className={`table-links ${selectedButton === 5 && 'active'}`}
              >
                Migraine headaches
              </button>
            </li>
            <li>
              <button
                onClick={() => setSelectedButton(6)}
                type="button"
                disabled
                className={`table-links ${selectedButton === 6 && 'active'}`}
              >
                Arthritic pain
              </button>
            </li>
          </ul>
          <div className="w-full h-auto border-l-2 border-slate-300 p-5">
            {/* Btn 1 faq area */}
            <div
              className={`w-full h-full ${
                selectedButton === 1 ? 'relative' : 'hidden'
              }`}
            >
              <h3 className="font-semibold text-xl mb-2 text-gray-600">
                Back, neck & shoulder pain
              </h3>
              <p className="text-gray-500 px-4 pb-4">Including:</p>
              <div className="text-gray-600 px-6">
                <div className="">
                  <ul className="grid grid-cols-2 mx-auto text-start">
                    {backNeckShoulder.map(({ name }, index: number) => (
                      <li
                        key={index}
                        className="flex items-start justify-start flex-row mb-1"
                      >
                        <span className="">
                          <FiTarget className="h-[24px] mr-1" />
                        </span>

                        <p className="m-0 p-0">{name}</p>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
            {/* btn2 faq area */}
            <div
              className={`w-full h-full ${
                selectedButton === 2 ? 'relative' : 'hidden'
              }`}
            >
              <h3 className="font-semibold text-xl mb-2 text-gray-600">
                Pain, discomfort & stiffness in joints
              </h3>
              <p className="text-gray-500 px-4 pb-4">Including:</p>
              <div className="text-gray-600 px-6">
                <div className="">
                  <ul className="grid grid-cols-2 items-center mx-auto">
                    {painDiscomfortStiffness.map(({ name }, index: number) => (
                      <li
                        key={index}
                        className="flex items-start justify-start flex-row mb-1"
                      >
                        <span className="">
                          <FiTarget className="h-[24px] mr-1" />
                        </span>

                        <p className="m-0 p-0">{name}</p>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fade>
    </div>
  );
};

export default TabMenu;
