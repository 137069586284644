import { videoData } from '../../types/types';

type Props = {
  videoData: videoData;
};

const VideoBox = ({ videoData }: Props) => {
  return (
    <div className="mt-10">
      {/* Video Player */}
      <section>
        <video
          key={videoData.letter}
          className="to-animate intro-animate-3 fadeInUp animated rounded-lg drop-shadow-2xl"
          width="100%"
          height="100%"
          poster={videoData.poster}
          controls
        >
          <source src={videoData.videoMp4} type="video/mp4" />
          <source src={videoData.videoWebM} type="video/webm" />
          Your browser doesn't support the video element.
        </video>
      </section>
      {/* Video Description */}
      <div className="flex flex-col items-center justify-center my-5 border-b-[1px] w-fit mx-auto">
        <h3 className="text-[30px] my-3">
          <span className="capitalize">{videoData.letter}: </span>
          {videoData.title}
        </h3>
        <p className="text-[20px] text-gray-600 mb-6">
          <span className="font-semibold text-black">Benefits: </span>
          {videoData.description}
        </p>
      </div>
    </div>
  );
};

export default VideoBox;
