export const fees = [{
  name: 'First Appointment & Treatment',
  options: [{
    title: 'Adult',
    cost: '75'
  },
  {
    title: 'Child (8-16)',
    cost: '75'
  }
  ]
},
{
  name: 'First Appointment & Treatment with Principal Chiropractor',
  options: [{
    title: 'Adult',
    cost: '85'
  },
  {
    title: 'Child (0-16)',
    cost: '85'
  }
  ]
},
{
  name: 'Standard Appointment',
  options: [{
    title: 'Adult',
    cost: '46'
  },
  {
    title: 'Child (0-16)',
    cost: '46'
  }
  ]
},
{
  name: 'Massage',
  options: [{
    title: 'Massage',
    cost: '45'
  }]
},
]

export const openingHours = [{
  day: 'Sunday',
  hours: 'Closed all day'
},
{
  day: 'Monday',
  hours: '9:00 - 18:00'
},
{
  day: 'Tuesday',
  hours: '10:00 - 18:00'
},
{
  day: 'Wednesday',
  hours: '10:00 - 18:00'
},
{
  day: 'Thursday',
  hours: '13:00 - 20:00'
},
{
  day: 'Friday',
  hours: '10:00 - 18:00'
},
{
  day: 'Saturday',
  hours: '10:00 - 14:00'
}
]