import SinglePost from '../SinglePost/SinglePost';
import logo from '../../../assets/images/logo.png';

type Props = {
	posts: Post[];
}

const NewsPage = ({ posts, }: Props) => {
	if (posts.length === 0) {
		return (
			<div className='flex flex-col items-center justify-center w-full h-[calc(100dvh-142px)] grow'>
				<img src={logo} alt="loading" className='' />
				<p className='my-2 w-fit text-bold'>Sorry, No News Yet!</p>
			</div>
		);
	}

	return (
		<div className='grid grid-cols-1 gap-4 p-4 md:grid-cols-2 lg:grid-cols-2'>
			{posts.map((post) => (
				<SinglePost key={post.id} post={post} />
			))}
		</div>
	);
}

export default NewsPage