import { PageTitle, FaqDropDown } from '../../components';

import { faqAnswers } from '../../static/faq';
import SpineAnimationWebM from '../../assets/animations/animation-spine.webm';
import SpineAnimationMp4 from '../../assets/animations/animation-spine.mp4';

import Fade from 'react-reveal/Fade';

const Faq = () => {
  return (
    <div className="py-12 bg-white">
      <div className="max-w-5xl mx-auto px-5">
        <PageTitle title="FAQ" />
        <div className="w-full h-[50px] relative sm:hidden md:block">
          <Fade right distance="400px" duration={5000}>
            <video
              className="absolute h-[250px] w-[250px] lg:h-[320px] lg:w-[320px] lg:top-[-190px] top-[-138px] right-0"
              loop
              autoPlay
              muted
              playsInline
            >
              <source src={SpineAnimationWebM} type="video/webm" />
              <source src={SpineAnimationMp4} type="video/mp4" />
            </video>
          </Fade>
        </div>
        <div className="relative px-5">
          {faqAnswers.map((answer, index) => (
            <FaqDropDown
              key={index}
              title={answer.title}
              description={answer.description ? answer.description : ''}
              link={answer.link ? answer.link : false}
              linkTo={answer.linkTo}
              image={answer.image}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Faq;
