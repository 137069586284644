import { PageTitle, TeamMember } from '../../components';
import Fade from 'react-reveal/Fade';
import team from '../../static/team';
import { TeamProps } from '../../types/types';

const MeetTheTeam = () => {
  return (
    <div className="py-12 bg-white">
      <div className="max-w-5xl px-2 mx-auto">
        <PageTitle title="Meet The Team" />
        <div className="grid mt-6 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 md:px-5">
          {team?.map(
            ({ name, title, description, image }: TeamProps, index: number) => (
              <Fade distance="30px" bottom key={index}>
                <div className="animated-cust">
                  <TeamMember
                    name={name}
                    title={title}
                    description={description}
                    image={image}
                  />
                </div>
              </Fade>
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default MeetTheTeam;
