import { Link } from 'react-router-dom';

import { BsInstagram, BsFacebook, BsTwitter } from 'react-icons/bs';

const UpperHeaderBar = () => {
  return (
    <div className="hidden md:flex w-full h-auto bg-white-500 justify-center items-center px-5 py-1 bg-white">
      <div className="flex w-full justify-between items-center max-w-5xl">
        <p className="text-small text-gray-500">
          Telephone booking and information: &nbsp;
          <a
            href="tel:01772379520"
            className="text-[#846bb9] hover:text-[#6bb2b9]"
          >
            01772 379520
          </a>
          <span> | </span>
          <a
            href="mailto:enquiries@kenworthychiropractic.com"
            className="text-[#846bb9] hover:text-[#6bb2b9] mx-1 ease-in-out duration-300"
          >
            enquiries@kenworthychiropractic.com
          </a>
        </p>
      </div>
      <div className="flex items-center">
        <Link
          target="_blank"
          rel="noopener noreferrer"
          to="https://www.facebook.com/KenworthyChiropractic/"
          className="text-[#846bb9] hover:text-[#6bb2b9] mx-1 ease-in-out duration-300"
        >
          <BsFacebook />
        </Link>
        <Link
          target="_blank"
          rel="noopener noreferrer"
          to="https://www.instagram.com/kenworthychiropractic/"
          className="text-[#846bb9] hover:text-[#6bb2b9] mx-1 ease-in-out duration-300"
        >
          <BsInstagram />
        </Link>
        <Link
          target="_blank"
          rel="noopener noreferrer"
          to="https://twitter.com/KenworthyChiro"
          className="text-[#846bb9] hover:text-[#6bb2b9] mx-1 ease-in-out duration-300"
        >
          <BsTwitter />
        </Link>
      </div>
    </div>
  );
};

export default UpperHeaderBar;
