import React from 'react';

import './styles.css';

type props = {
  banner: boolean;
};

const cornerBanner = ({ banner }: props) => {
  return (
    <div
      className={`hidden lxs:flex absolute top-0 ${
        banner
          ? 'bg-primary sparkling-background pb-2 rounded-b-xl shadow-xl flex-col justify-end items-center w-[100px] mxs:w-[150px] h-[130px] mxs:h-[180px]'
          : 'w-[130px] pt-2'
      }   `}
    >
      <div
        className={`top-[5px] flex flex-row items-center ${
          banner ? 'absolute' : 'hidden'
        }`}
      >
        <div className="bg-yellow-600 h-[15px] w-[15px] rounded-l-full shadow-md"></div>
        <div className="bg-yellow-600 h-[10px] w-[110px] mxs:w-[160px]  shadow-md"></div>
        <div className="bg-yellow-600 h-[15px] w-[15px] rounded-r-full shadow-md"></div>
      </div>
      <a
        target="_blank"
        rel="noreferrer"
        href="https://threebestrated.co.uk/chiropractors-in-preston"
        className="inline border-0"
      >
        <img
          className={`block w-full`}
          src="https://threebestrated.co.uk/awards/chiropractors-preston-2023-drk.svg"
          alt="Best Chiropractors in Preston"
        />
      </a>
    </div>
  );
};

export default cornerBanner;
