import './styles.css';

import { TeamProps, memberDescription } from '../../types/types';

import { AiFillStar } from 'react-icons/ai';
import { FaAngleDown } from 'react-icons/fa';
import { Spin as Hamburger } from 'hamburger-react';
import { useState } from 'react';

const TeamMember = ({ name, title, description, image }: TeamProps) => {
  const [viewDescription, setViewDescription] = useState(false);

  return (
    <div className="relative mx-auto mb-10 transition-all duration-300 team-member-container ease">

      <div className="transition-all duration-300 ease">

        {/* Button for description */}
        <button
          onClick={() => {
            viewDescription
              ? setViewDescription(false)
              : setViewDescription(true);
          }}
          type="button"
          className={`
              ${viewDescription
              ?
              'top-[16%]'
              :
              'top-[20px]'
            }
              bg-white text-white absolute right-[5px]  rounded-full h-[50px] w-[50px] flex justify-center items-center group z-[3] transition-all duration-300 ease
              `}
        >
          <div className={`bg-[#846bb9] group-hover:bg-[#7251bb] rounded-full w-[40px] h-[40px] overflow-hidden flex justify-center items-center transition-all duration-300 ease `}>
            <div className="h-fit w-fit">
              <Hamburger
                toggled={viewDescription}
                size={15}
                direction="right"
                label="Menu"
              />
            </div>
          </div>
        </button>

        {/* Image  */}
        <img
          src={image}
          alt={name}
          className={`
          ${viewDescription ?
              'img-small rounded-full z-[4] w-[50px] h-[50px] top-[15px]'
              :
              'z-[2] rounded-none w-full h-full top-[0px]'
            }
             left-0 absolute overflow-hidden transition-all duration-300 ease
          `}
        />


        {/* <div className="bg-white absolute top-0 right-0 w-full h-full z-[1] transition-all duration-300 ease"></div> */}

        {/* Banner */}
        <div className={`

        ${viewDescription ?
            'banner-2-up bottom-[62%] skew-y-[35deg]'
            :
            'banner-2-down bottom-[72px] skew-y-[-35deg]'
          }
           hidden sm:block absolute bg-[#239a96] w-[200px] h-[79px] z-[0]  left-[-8px] transition-all duration-300 ease
          `}></div>

        <div className={`
        ${viewDescription ?
            'banner-1-up bottom-[80%] pl-[70px]'
            :
            'banner-1-down'
          } absolute bottom-0 right-0 bg-[#2ab8b4] w-[102%] h-[80px] z-[2] text-white p-[12px] transition-all duration-300 ease
          `}>
          <h3 className={`transition-all duration-300 ease font-bold sm:text-2xl`}>{name}</h3>
          <p className="flex items-center font-semibold transition-all duration-300 text-m ease">
            <span className="mr-1 ">
              <AiFillStar />
            </span>
            {title}
          </p>
        </div>

        {/* Description Area */}
        <div className="description-container bg-[#ecfffe] absolute bottom-0 right-0 left-0 w-full h-full z-[1] rounded-lg pt-[28%] pb-[40px] px-7 transition-all duration-300 ease">
          <div className="w-full h-full overflow-y-scroll transition-all duration-300 description-container--inner ease">
            {description.map(
              ({ paragraph }: memberDescription, index: number) => {
                return (
                  <p key={index} className="mt-3">
                    {paragraph}
                  </p>
                );
              }
            )}
          </div>
        </div>

        <div className={`${viewDescription ? 'visible' : 'hidden'} absolute  bottom-0 z-[4] w-full flex justify-center items-center transition-all duration-500 ease`}>
          <FaAngleDown className="text-[30px] arrow bounce" />
        </div>
      </div>
    </div>
  );
};

export default TeamMember;
