import { PageTitle } from '../../components';
import {
  painReliefCitations,
  pregnancyInfancyCitations,
  sportInjuryCitations,
} from '../../static/citations';

const Fade = require('react-reveal/Fade');

type citationProp = {
  link?: string;
  infoText?: string;
  ariaLabel: string;
  citationNumber: number;
};

const Citations = () => {
  return (
    <div className="bg-white py-12">
      <div className="max-w-5xl mx-auto lxs:px-1 sm:px-5 ">
        <PageTitle title="Citations & Referencing" />

        <div className="w-full mt-8 flex flex-col justify-center items-start mx-auto max-w-5xl">
          <div className="mt-8 w-full h-auto">
            <h4 className="font-semibold text-xl pb-6">Pain Relief</h4>

            {painReliefCitations.map(
              (
                { link, citationNumber, infoText, ariaLabel }: citationProp,
                index: number
              ) => (
                <Fade key={index} distance={`${index * 10 + 10}px`} bottom>
                  <div
                    key={index}
                    className="flex items-start justify-start flex-row my-6 w-auto h-auto overflow-x-scroll sm:overflow-hidden"
                    id={`A${citationNumber}`}
                  >
                    <span className="font-semibold flex items-center select-none">
                      A{citationNumber}.
                    </span>
                    {link && infoText ? (
                      <p className="flex ml-1" id={`C${citationNumber}`}>
                        {link ? (
                          <a
                            href={link}
                            aria-label={ariaLabel}
                            className="text-[#846bb9] hover:text-[#6bb2b9]"
                            target="_blank"
                            rel="noreferrer"
                          >
                            {infoText}
                          </a>
                        ) : (
                          infoText
                        )}
                      </p>
                    ) : (
                      <p className="flex ml-1" id={`C${citationNumber}`}>
                        {link ? (
                          <a
                            href={link}
                            aria-label={ariaLabel}
                            className="text-[#846bb9] hover:text-[#6bb2b9]"
                            target="_blank"
                            rel="noreferrer"
                          >
                            {link}
                          </a>
                        ) : (
                          infoText
                        )}
                      </p>
                    )}
                  </div>
                </Fade>
              )
            )}
          </div>

          <div className="mt-8 w-full h-auto">
            <h4 className="font-semibold text-xl pb-6">Pregnancy & Infancy</h4>

            {pregnancyInfancyCitations.map(
              (
                { link, citationNumber, infoText, ariaLabel }: citationProp,
                index: number
              ) => (
                <Fade key={index} distance={`${index * 10 + 10}px`} bottom>
                  <div
                    key={index}
                    className="flex items-start justify-start flex-row my-6 w-auto h-auto overflow-x-scroll sm:overflow-hidden"
                    id={`B${citationNumber}`}
                  >
                    <span className="font-semibold flex items-center select-none">
                      B{citationNumber}.
                    </span>
                    {link && infoText ? (
                      <p className="flex ml-1" id={`C${citationNumber}`}>
                        {link ? (
                          <a
                            href={link}
                            aria-label={ariaLabel}
                            className="text-[#846bb9] hover:text-[#6bb2b9]"
                            target="_blank"
                            rel="noreferrer"
                          >
                            {infoText}
                          </a>
                        ) : (
                          infoText
                        )}
                      </p>
                    ) : (
                      <p className="flex ml-1" id={`C${citationNumber}`}>
                        {link ? (
                          <a
                            href={link}
                            aria-label={ariaLabel}
                            className="text-[#846bb9] hover:text-[#6bb2b9]"
                            target="_blank"
                            rel="noreferrer"
                          >
                            {link}
                          </a>
                        ) : (
                          infoText
                        )}
                      </p>
                    )}
                  </div>
                </Fade>
              )
            )}
          </div>

          <div className="mt-8 w-full h-auto">
            <h4 className="font-semibold text-xl pb-6">Sports & Injury</h4>

            {sportInjuryCitations.map(
              (
                { link, citationNumber, infoText, ariaLabel }: citationProp,
                index: number
              ) => (
                <Fade key={index} distance={`${index * 10 + 10}px`} bottom>
                  <div
                    key={index}
                    className="flex items-start justify-start flex-row my-6 w-auto h-auto overflow-x-scroll sm:overflow-hidden"
                    id={`C${citationNumber}`}
                  >
                    <span className="font-semibold flex items-center select-none">
                      C{citationNumber}.
                    </span>
                    {link && infoText ? (
                      <p className="flex ml-1" id={`C${citationNumber}`}>
                        {link ? (
                          <a
                            href={link}
                            aria-label={ariaLabel}
                            className="text-[#846bb9] hover:text-[#6bb2b9]"
                            target="_blank"
                            rel="noreferrer"
                          >
                            {infoText}
                          </a>
                        ) : (
                          infoText
                        )}
                      </p>
                    ) : (
                      <p className="flex ml-1" id={`C${citationNumber}`}>
                        {link ? (
                          <a
                            href={link}
                            aria-label={ariaLabel}
                            className="text-[#846bb9] hover:text-[#6bb2b9]"
                            target="_blank"
                            rel="noreferrer"
                          >
                            {link}
                          </a>
                        ) : (
                          infoText
                        )}
                      </p>
                    )}
                  </div>
                </Fade>
              )
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Citations;
