import { Review } from '../index';
import Slider from 'react-slick';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

type ReviewsProp = {
  name: string;
  review: string;
  rating: number;
};

type Props = {
  reviewObj: ReviewsProp[];
};

const Reviews = ({ reviewObj }: Props) => {
  const Fade = require('react-reveal/Fade');
  const settings = {
    arrows: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    pauseOnFocus: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    initialSlide: 0,
    className: 'center',
    dots: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          dots: true,
        },
      },
      {
        breakpoint: 790,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          initialSlide: 2,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
          dots: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
        },
      },
    ],
  };

  return (
    <div className="bg-[#2ab8b4] w-full py-10 px-12">
      <div className="max-w-8xl mx-auto">
        <Fade distance="30px" bottom>
          <h2 className="text-white text-[40px] font-bold text-center mb-5">
            Latest Reviews
          </h2>
        </Fade>
        {/* Review Carousel */}
        <Slider {...settings}>
          {reviewObj.map(
            ({ name, review, rating }: ReviewsProp, index: number) => (
              <div key={index} className="px-5 ">
                <div className="flex justify-center items-center">
                  <div className="bg-[#0000006f] min-w-[180px] h-[250px] p-5 rounded-lg">
                    <Review name={name} review={review} rating={rating} />
                  </div>
                </div>
              </div>
            )
          )}
        </Slider>
      </div>
    </div>
  );
};

export default Reviews;
