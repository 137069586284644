import {
  DynamicDays,
  Reviews,
  RoundPageLink,
  WelcomeArea,
} from '../../components';

import Fade from 'react-reveal/Fade';
import { GiFilmStrip } from 'react-icons/gi';
import { MdOutlineRssFeed } from 'react-icons/md';
// import { RiPsychotherapyLine } from 'react-icons/ri';
import { RiQuestionMark } from 'react-icons/ri';
import { reviews } from '../../static/reviews';

const Home = () => {
  return (
    <>

      <div className="flex flex-col w-full justify-start items-center bg-[#ffffff]">
        <DynamicDays />
        <div className="flex items-center justify-between w-full max-w-5xl">
          {/* <div className="grid items-center justify-center w-full grid-flow-row grid-cols-2 my-12 border-b-2 xs-grid-cols-1 md:grid-cols-4 lg:grid-cols-8"> */}
          <div className="flex flex-row flex-wrap items-center justify-center w-full my-12 border-b-2">
            <Fade distance="30px" bottom>

              <RoundPageLink path="pain-relief" icon="a" text="Pain Relief" />
              <RoundPageLink
                path="pregnancy-infancy"
                icon="l"
                text="Pregnancy & Infancy"
              />
              <RoundPageLink
                path="sport-injury"
                icon="b"
                text="Sport & Injury"
              />
              {/* <RoundPageLink
                path="other-services"
                icon={<RiPsychotherapyLine />}
                text="Other Services"
              /> */}
              <RoundPageLink path="fees" icon="h" text="Fees & Opening Hours" />
              <RoundPageLink
                path="video-exercises"
                icon={<GiFilmStrip />}
                text="Video Exercises"
              />
              <RoundPageLink
                path="news"
                icon={<MdOutlineRssFeed />}
                text="News"
              />
              <RoundPageLink path="faq" icon={<RiQuestionMark />} text="FAQ" />
            </Fade>
          </div>
        </div>
        <div className="max-w-5xl">
          {/* <Fade distance="30px" bottom> */}
          <WelcomeArea />
          {/* </Fade> */}
        </div>
        <div className="w-full flex flex-col justify-center items-center bg-[#2ab8b4]">
          <Reviews reviewObj={reviews} />
        </div>
      </div>
    </>
  );
};

export default Home;
