import { useState } from 'react';
import { FaCaretDown } from 'react-icons/fa';
import { PageTitle, VideoBox } from '../../components';

import { AllVideoExercises } from '../../static/video-exercises';
import { VideoExercisesProps, videoData } from '../../types/types';

const Fade = require('react-reveal/Fade');

const VideoExercises = () => {
  const [videoData, setVideoData] = useState<videoData>();
  const [disclaimerOpen, setDisclaimerOpen] = useState(false);

  const handleOpenDisclaimer = () => {
    disclaimerOpen ? setDisclaimerOpen(false) : setDisclaimerOpen(true);
  };

  return (
    <div className="bg-white py-12">
      <div className="max-w-5xl mx-auto px-5">
        <PageTitle title="Exercise Videos" />
        <div className="mt-5 px-5 text-gray-500">
          <p>
            Welcome to the Kenworthy Chiropractic video exercise page. Please
            follow these exercises as part of your clinic treatment plan.
            Johanna will indicate which exercises are relevant to you during
            your appointment and will provide further instructions regarding the
            duration and frequency of practice.
          </p>
        </div>
        {videoData && (
          <div className="px-5">
            <Fade bottom distance="30px">
              {/* VIDEO BOX */}
              <VideoBox videoData={videoData} />
            </Fade>
          </div>
        )}

        <div className=" mx-auto grid grid-cols-2 md:grid-cols-4 lg:grid-cols-8 py-0 sm:py-2">
          {/* Buttons */}
          <Fade bottom distance="30px">
            {AllVideoExercises.map(
              (
                { letter, btnBg, videoData }: VideoExercisesProps,
                index: number
              ) => (
                <button
                  className={`relative w-[100px] h-[100px] bg-gray-500 hover:drop-shadow-2xl rounded-full text-[30px] font-bold capitalize overflow-hidden bottom-0 hover:bottom-[20px] ease-in-out transition-all duration-300 my-10 mx-auto`}
                  style={{
                    backgroundImage: `url(${btnBg})`,
                    backgroundPosition: `center center`,
                    backgroundSize: `contain`,
                  }}
                  key={index}
                  type="button"
                  onClick={() => setVideoData(videoData)}
                >
                  <p className=" text-white">{letter}</p>
                </button>
              )
            )}
          </Fade>
        </div>

        {/* Disclaimer */}
        <div
          className={`h-auto ease-in-out duration-1000 transition-all w-fit border-[1px] bg-[#efefef] border-slate-500 text-[#846bb9] mb-[10px] rounded-xl flex flex-col justify-center items-center mx-auto overflow-hidden`}
        >
          <div
            className={`w-full flex flex-col justify-between items-center pt-1 pl-2 `}
          >
            <button
              className={`w-full flex justify-center items-center pt-1 pl-2 cursor-pointer`}
              onClick={() => {
                handleOpenDisclaimer();
              }}
            >
              <h2 className="text-xl">Disclaimer</h2>
              <div>
                <FaCaretDown
                  className={`w-[30px] h-[30px] transition-all ease-in-out duration-1000${
                    disclaimerOpen && 'rotate-180'
                  }`}
                />
              </div>
            </button>
            <div
              className={`max-w-[500px] px-2 sm:px-10 text-center italic ${
                disclaimerOpen
                  ? 'max-h-[500px] mb-5 ease-in-out duration-1000 transition-all'
                  : 'max-h-[0px] ease-in-out duration-1000 transition-all'
              }`}
            >
              <p className="mt-4">
                These exercises should only be undertaken by patients of
                Kenworthy Chiropractic clinic under the guidance of a qualified
                chiropractor. Kenworthy Chiropractic does not accept liability
                for any accident, injury or mishap resulting from these
                exercises.
              </p>
              <br />
              <p>
                Videos by:&nbsp;
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.whalehello.co.uk"
                  className="underline hover:text-[#6bb2b9]"
                >
                  WhaleHello
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoExercises;
