import { AwesomeButton } from 'react-awesome-button';
import 'react-awesome-button/dist/styles.css';
import Fade from 'react-reveal/Fade';

declare interface specialDayText {
  paragraph: String;
}

declare interface specialDayVideo {
  link: string;
  type: string;
}

declare interface DynamicDayProps {
  title: string;
  text?: specialDayText[];
  textLoc?: string;
  image?: string;
  video?: specialDayVideo[];
  videoPoster?: string;
  bgColor?: string;
  background?: string;
  animation?: string;
}

const DynamicDay = ({
  title,
  text, // The text information (String_)
  textLoc, // Location of the text (left, center, right)
  image, // url for image
  video, // array of video sources
  videoPoster, //video poster image for mobile
  bgColor, //Accepts tailwind properties. prepended with "bg-". Add [] for custom color. Eg. [#fff] will create bg-[#fff] in component
  background, // url for Background image
  animation, // url for animation
}: DynamicDayProps) => {
  return (
    <section
      className={`relative min-h-[150px] max-h-auto w-full flex flex-col items-center py-6 overflow-hidden pb-4	${
        bgColor && `${bgColor}`
      }`}
    >
      {background && (
        <img
          src={background}
          alt="Special Day Background"
          className="absolute top-0 h-fit w-full z-[0] bg-cover"
        />
      )}
      <Fade bottom cascade>
        <div
          className={`max-w-5xl grid grid-cols-1 lg:grid-cols-2 gap-x-8 items-center z-[1] mx-auto h-auto`}
        >
          <div
            className={`relative border-transparent	bg-[#856bb9ed] rounded-lg p-5 max-w-[90%] mx-auto my-4 text-center text-white box-shadow shadow-1xl shadow-[#846cb99c] h-fit ${
              textLoc === 'left' && 'order-1'
            } ${textLoc === 'right' && 'order-2'}}`}
          >
            {/* Title */}
            <h4
              className={`lg:text-2xl text-xl font-semibold ${
                text && text.length > 1 && 'pb-2'
              }`}
            >
              {title}
            </h4>
            <div
              className={`overflow-scroll py-2 md:overflow-auto md:max-h-full transition-all duration-400 ease-in-out sm:max-h-[100px] max-h-[100px]`}
            >
              {/* Text */}
              {text &&
                text.map((paragraph, index) => {
                  return (
                    <p
                      key={index}
                      className={`lg:text-md text-sm mb-2 text-justify`}
                    >
                      {paragraph.paragraph}
                    </p>
                  );
                })}
            </div>
            <div className="my-4">
              <AwesomeButton
                type="primary"
                href="bookings"
                className="w-fit md:h-fit max-w-full max-h-[30px] font-sm"
              >
                Book Online
              </AwesomeButton>
            </div>
            <div className="bottom-[5px] h-fit absolute contents p-0 m-0 md:hidden"></div>
          </div>
          {image || animation || video ? (
            <div
              className={`overflow-hidden rounded-xl w-auto max-w-[90%]  mx-auto ${
                textLoc === 'left' && 'order-2'
              } ${textLoc === 'right' && 'order-1'} `}
            >
              {/* Image */}
              {image ? (
                <img
                  src={image}
                  alt="Special Day Animation"
                  className="h-full w-full"
                />
              ) : null}
              {/* Animation */}
              {animation ? (
                <img
                  src={animation}
                  alt="Special Day Animation"
                  className="h-full w-full"
                />
              ) : null}
              {/* Video */}
              {video && video.length >= 1 ? (
                <video
                  loop
                  autoPlay
                  muted
                  controls
                  controlsList="nodownload"
                  className="w-full max-h-[300px] rounded-xl object-contain aspect-video"
                  poster={videoPoster}
                >
                  {video.map(({ link, type }: specialDayVideo) => {
                    return (
                      <source key={type} src={link} type={`video/${type}`} />
                    );
                  })}
                  Your browser does not support the video tag.
                </video>
              ) : null}
            </div>
          ) : null}
        </div>
      </Fade>
    </section>
  );
};

export default DynamicDay;
