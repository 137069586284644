import { AwesomeButton } from 'react-awesome-button';

type openingHoursObject = {
  day: string;
  hours: string;
};

type Props = {
  openingHours: openingHoursObject[];
};

const HoursTable = ({ openingHours }: Props) => {
  return (
    <div className="w-full m-4 bg-gray-100 border-2 border-black-100 h-auto p-10 flex flex-col justify-start min-w-[200px] max-w-xl">
      <div className="text-[#846bb9] flex flex-col justify-center items-center">
        <h2 className="font-semibold text-2xl">Opening Hours</h2>
      </div>
      {/* Fees Area */}
      <div className="max-w-4xl">
        {openingHours.map(({ day, hours }) => (
          <div className="flex flex-row justify-between text-gray-500 py-2 border-b-2 border-gray-300 my-4">
            <p className="text-sm">{day}</p>
            <p className="text-sm font-bold">{hours}</p>
          </div>
        ))}
      </div>
      <div className="mt-5 flex items-center justify-center">
        <AwesomeButton type="primary" href="bookings">
          Book Online
        </AwesomeButton>
      </div>
    </div>
  );
};

export default HoursTable;
