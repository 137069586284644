import { Loader, PageTitle } from '../../components';

import NewsPage from '../../components/News/NewsPage/NewsPage';
import ReactPaginate from 'react-paginate';
import clientConfig from '../../client-config';
import logo from '../../assets/images/logo.png';
import useFetchAllPosts from '../../hooks/useFetchAllPosts';
import { useState } from 'react';

const News = () => {
	// The wordpress site url
	const wordPressSiteURL = clientConfig.siteUrl;

	// States
	const [page, setPage] = useState<number>(1);


	// Hooks

	// Fetch all posts hook
	const { posts, loading, error, totalPosts } = useFetchAllPosts(wordPressSiteURL, page);

	if (loading) return (
		<Loader logo={logo} textLineOne="Loading News!" />
	);

	if (error) return (
		<Loader logo={logo} textLineOne="Error Loading News!" textLineTwo="Please try refreshing the page." />
	);

	// Functions

	// Invoke when user click to request another page.
	const handlePageClick = (event: any) => {
		const newOffset = (event.selected + 1);
		console.log(
			`User requested page number ${event.selected}, which is offset ${newOffset}`
		);
		setPage(newOffset);
		window.scrollTo({
			top: 0,
			behavior: 'smooth', // For smooth scrolling
		});
	};


	return (
		<div className='py-12 bg-white '>
			<div className="max-w-5xl px-2 mx-auto">
				{/* Title */}
				<PageTitle title="News" />

				{/* Text */}
				<p className="text-gray-600">
					Welcome to the Kenworthy Chiropractic news page. Check in frequently to find out what’s happening at the clinic. We’ll be posting all the latest news as well as hints and tips to improve your health, chiropractic articles, videos and even the occasional competition.
				</p>

				<NewsPage posts={posts} />
				<div className='flex flex-row items-center justify-center '>
					<ReactPaginate
						containerClassName='flex flex-row items-center justify-center gap-2 text-2xl  text-primary'
						pageLinkClassName={`font-normal hover:underline hover:text-secondary transition-all duration-200 ease`}
						activeClassName={`font-bold underline text-secondary select-none`}
						previousClassName={`${page < 2 && 'hidden'} text-primary hover:text-secondary transition-all duration-200 ease hover:scale-110`}
						nextClassName={`${page >= (totalPosts / 10) && 'hidden'} text-primary hover:text-secondary transition-all duration-200 ease hover:scale-110`}
						breakLabel="..."
						nextLabel=">"
						onPageChange={handlePageClick}
						pageRangeDisplayed={3}
						pageCount={Math.ceil(totalPosts / 10)}
						previousLabel="<"
						renderOnZeroPageCount={null}
					/>
				</div>

			</div>
		</div>
	);

};

export default News;
